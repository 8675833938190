import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../common/state/store";
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";
import { IsLoading } from "../../common/localComponents/AppState/IsLoading";
import { poolsColumns } from "./pools-columns";
import { Link } from "react-router-dom";
import {
  getMyCurrentFullListOfPools,
  getFullListOfMyPools,
} from "../../features/pools/state/poolsSlice";
import { ButtonIcon, DataGridComponent } from "ui";
import bgImage from "../../assets/bgMyCtoFriend.jpeg";
import { CursorArrowRaysIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

export const USERS_COMPONENT_ID = "poolsComponent";

const Pools = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const [pageSize, setPageSize] = useState(20);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    firstname: false,
    lastname: false,
  });

  const { fullListOfPools } = useSelector((state: RootState) => {
    return {
      fullListOfPools: getMyCurrentFullListOfPools(state),
    };
  });

  useEffect(() => {
    if (!fullListOfPools || fullListOfPools.length === 0) {
      dispatch(getFullListOfMyPools({ componentId: USERS_COMPONENT_ID }));
    }
  }, []);

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: (params: any) => {
        return (
          <>
            <Link to={`/tracks/${params.id}`}>
              <button className="btn-primary-fill bg-blue mr-2">{String(t("general.open"))}</button>
            </Link>
          </>
        );
      },
    },
  ];

  return (
    <>
      <header className="flex w-full bg-white shadow  sticky top-12 z-10">
        <div className="max-w-screen-2xl py-6 px-8 overflow-y-auto">
          <h1 className="text-3xl font-bold text-black">{t("pages.pools.title")}</h1>
        </div>
        <div className="flex justify-end align-middle ml-auto my-auto mr-8">
          <ButtonIcon
            icon={"ArrowPathIcon"}
            classNameBtn={"w-14 h-14 bg-white rounded-full shadow-md border"}
            classNameIcon={"w-10 h-10"}
            onClick={() => {
              dispatch(getFullListOfMyPools({ componentId: USERS_COMPONENT_ID }));
            }}
          />
        </div>
      </header>
      <div
        className="w-full h-full bg-cover"
        style={{
          backgroundImage: `url('${bgImage}')`,
          boxShadow: "inset 0px 0px 400px 400px rgba(0, 0, 0, .03)",
        }}
      >
        <div className="w-full h-full pb-6 max-w-9xl mx-auto pt-6 px-6">
          <IsLoading componentId={USERS_COMPONENT_ID} showSuccess={false}>
            <div className="space-y-4">
              {fullListOfPools?.map((pool: any) => (
                <div
                  key={pool.track.name}
                  className="bg-white rounded-2xl overflow-hidden shadow-md"
                >
                  <div
                    className="flex items-center justify-between cursor-pointer py-4 px-6"
                    onClick={() => {
                      //open new page tracks/${pool.id}
                      navigate(`/tracks/${pool.id}`);
                    }}
                  >
                    <div className="w-full flex justify-between">
                      <div className="flex flex-col">
                        <div className="text-lg font-medium text-gray-900">{pool.track.name}</div>
                        <div className="text-sm font-small text-gray-400">{`Status: ${pool.status.name}`}</div>
                      </div>
                      <div className="h-6 w-6 mr-2 flex my-auto justify-end">
                        <CursorArrowRaysIcon className="text-center" />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </IsLoading>
        </div>
      </div>
    </>
  );
};

export default Pools;
