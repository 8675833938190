import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IsLoading } from "../../common/localComponents/AppState/IsLoading";
import { AppDispatch, RootState } from "../../common/state/store";
import {
  getCurrentInfoAboutMyUser,
  getMyUser,
  updateMyUser,
} from "../../features/my-user/state/myUserSlice";
import {
  Input,
  CustomInputStyle,
  ButtonIcon,
} from "ui";
import { ErrorMessage } from "@hookform/error-message";
import bgImage from "../../assets/bgMyCtoFriend.jpeg";

export const GROUPS_COMPONENT_ID = "groupsComponent";
export const PROJECTS_COMPONENT_ID = "projectsComponent";
export const ZOOMMEETINGS_COMPONENT_ID = "ZoomMeetingsComponent";
export const GROUPS_NOT_IN_USER_COMPONENT_ID = "groupsNotInUserComponent";
export const PROJECT_UPDATE_COMPONENT_ID = "UpdateProjectsComponent";
export const UPDATE_GROUPS_IN_USER_COMPONENT_ID = "UpdateGroupsInUserComponent";
export const REMOVE_THIS_USER_FROM_PROJECTS_COMPONENT_ID = "removeThisProjectFromTheUserComponent";
export const REMOVE_THIS_USER_FROM_GROUP_COMPONENT_ID = "removeThisGroupFromTheUserComponent";
export const USER_UPDATE_COMPONENT_ID = "userUpdateComponent";
export const CHANGEPASSWORD_COMPONENT_ID = "changePasswordComponent";

type IProfileInfo = {
  firstname: string;
  lastname: string;
  email: string;
  groups: any[];
};

const MyProfile = () => {
  const { t } = useTranslation();

  const dispatch: AppDispatch = useDispatch();

  const { myProfile } = useSelector((state: RootState) => {
    return {
      myProfile: getCurrentInfoAboutMyUser(state),
    };
  });

  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<IProfileInfo>({
    defaultValues: {
      firstname: myProfile?.firstname,
      lastname: myProfile?.lastname,
      email: myProfile?.email,
    },
  });

  const [notEditableUser, setNotEditableUser] = useState(true);

  useEffect(() => {
    dispatch(
      getMyUser({
        componentId: ZOOMMEETINGS_COMPONENT_ID,
      }),
    ).then((res: any) => {
      console.log(res.payload);
      reset({
        firstname: res.payload?.firstname,
        lastname: res.payload?.lastname,
        email: res.payload?.email,
      });
    });
  }, []);

  const onSubmitUserInfo = (data: any) => {
    const updatedUser = {
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email?.toLocaleLowerCase(),
    };

    dispatch(
      updateMyUser({
        componentId: USER_UPDATE_COMPONENT_ID,
        data: updatedUser,
      }),
    )
      .then(() => {
        setNotEditableUser(true);
      })
      .catch((e: any) => {
        console.error("catch: ", e);
      });
  };

  const cancelUser = () => {
    reset({
      firstname: myProfile?.firstname,
      lastname: myProfile?.lastname,
      email: myProfile?.email,
    });

    setNotEditableUser(true);
  };

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <>
        <div className="absolute z-50 top-10 right-10">
          <ButtonIcon
            icon={"ArrowPathIcon"}
            classNameBtn={"w-14 h-14 bg-white rounded-full shadow-lg z-10 border"}
            classNameIcon={"w-10 h-10"}
            onClick={() => {
              dispatch(
                getMyUser({
                  componentId: ZOOMMEETINGS_COMPONENT_ID,
                }),
              ).then((res: any) => {
                console.log(res.payload);
                reset({
                  firstname: res.payload?.firstname,
                  lastname: res.payload?.lastname,
                  email: res.payload?.email,
                });
              });
            }}
          />
        </div>
      <div
        className="w-full h-full bg-cover"
        style={{
          backgroundImage: `url('${bgImage}')`,
          boxShadow: "inset 0px 0px 400px 400px rgba(0, 0, 0, .03)",
        }}
      >
        <IsLoading componentId={GROUPS_COMPONENT_ID} showSuccess={false}>
          <IsLoading componentId={PROJECTS_COMPONENT_ID} showSuccess={false}>
            <IsLoading componentId={ZOOMMEETINGS_COMPONENT_ID} showSuccess={false}>
              <IsLoading componentId={GROUPS_NOT_IN_USER_COMPONENT_ID} showSuccess={false}>
                <div className="flex justify-center items-center w-full h-full pb-6 pt-6 px-6">
                  <form onSubmit={handleSubmit(onSubmitUserInfo)} className="w-full max-w-5xl">
                    <div className="flex-grow mb-6 p-5 h-auto min-h-[200px] w-full bg-white rounded-xl shadow-lg z-10">
                      <div className="w-full h-full max-w-9xl">
                        <div className="relative grow w-full">
                          <div className="border-gray-300">
                            <div className="w-full flex flex-row mb-2">
                              <h2 className="pl-2 py-2 text-xl font-bold">
                                {t("pages.profile.info")}
                              </h2>
                              <div className="flex grow justify-end h-[38px] my-auto">
                                {notEditableUser && (
                                  <button
                                    className="btn-primary-fill"
                                    type="button"
                                    onClick={() => {
                                      setNotEditableUser(false);
                                    }}
                                  >
                                    {t("general.edit")}
                                  </button>
                                )}
                              </div>
                            </div>
                            <div className="grid grid-cols-1">
                               <div>  {/*"xl:order-1 xl:mr-2"*/}
                                <CustomInputStyle title={t("pages.profile.firstname")}>
                                  <Input
                                    type="text"
                                    readOnly={notEditableUser}
                                    registerFct={() => register("firstname")}
                                    className="font-medium read-only:bg-white read-only:border-white read-only:shadow-none read-only:focus:ring-0 text-gray-600 text-center sm:text-left ${ ? }"
                                    placeholder={t("pages.profile.firstNamePlaceholder")}
                                  />
                                </CustomInputStyle>
                              </div>
                              <div> {/*"xl:order-3 xl:mr-2"*/}
                                <CustomInputStyle title={t("pages.profile.lastname")}>
                                  <Input
                                    type="text"
                                    readOnly={notEditableUser}
                                    registerFct={() => register("lastname")}
                                    className="font-medium read-only:bg-white read-only:border-white read-only:shadow-none read-only:focus:ring-0 text-gray-600 text-center sm:text-left ${ ? }"
                                    placeholder={t("pages.profile.lastNamePlaceholder")}
                                  />
                                </CustomInputStyle>
                              </div>
                              <div> {/*"xl:order-2 xl:ml-2"*/}
                                <CustomInputStyle title={t("pages.profile.email")}>
                                  <Input
                                    type="email"
                                    readOnly={true}
                                    registerFct={() =>
                                      register("email", { required: true, pattern: /^\S+@\S+$/i })
                                    }
                                    className="font-medium read-only:select-text read-only:bg-white read-only:border-white read-only:shadow-none read-only:focus:ring-0 text-gray-600 text-center sm:text-left ${ ? }"
                                    placeholder={t("pages.profile.emailPlaceholder")}
                                  />
                                  <ErrorMessage
                                    errors={errors}
                                    name="email"
                                    render={() => (
                                      <div className="font-small text-red-700">
                                        {"Ne peux pas être vide!"}
                                      </div>
                                    )}
                                  />
                                </CustomInputStyle>
                              </div>
                              <div> {/*"xl:order-4 xl:ml-2"*/}
                                <CustomInputStyle title={t("pages.profile.googledocouidshared")}>
                                  <button
                                    className="btn-primary-fill h-[38px] ml-3 mt-1 sm:mr-auto md:my-auto"
                                    onClick={() =>
                                      openInNewTab(
                                        `https://docs.google.com/document/d/${myProfile.googledocouidshared}`,
                                      )
                                    }
                                  >
                                    {String(t("general.open"))}
                                  </button>
                                </CustomInputStyle>
                              </div>
                            </div>
                            <div className="flex justify-end h-[39px] mt-1">
                              <IsLoading componentId={USER_UPDATE_COMPONENT_ID} showSuccess={false}>
                                <>
                                  {notEditableUser === false && (
                                    <>
                                      <button
                                        type="button"
                                        className="btn-primary-outline mr-4"
                                        onClick={() => cancelUser()}
                                      >
                                        {t("general.cancel")}
                                      </button>
                                      <button className="btn-primary-fill" type="submit">
                                        {t("general.save")}
                                      </button>
                                    </>
                                  )}
                                </>
                              </IsLoading>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </IsLoading>
            </IsLoading>
          </IsLoading>
        </IsLoading>
      </div>
    </>
  );
};

export default MyProfile;
