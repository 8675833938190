import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import apiUrls from "../../../api";
import { IAppLoaderAction } from "../../../common/state/loaderHandleMiddleware";
import { RootState } from "../../../common/state/store";

export interface IPools {
  fullListOfMyPools?: any[];
  specificPool: any;
  projectsThatAreNotInThePool: any[];
  groupsThatAreNotInThePool: any[];
}

export const getFullListOfMyPools = createAsyncThunk(
  "pools/getFullListOfMyPools",
  async (componentId: IAppLoaderAction) => {
    let response;
    await axios
      .post(`${apiUrls.pools.getMy}?_sort=id:DESC&_limit=-1`)
      .then((res) => {
        response = res.data;
      })
      .catch((e) => {
        console.error(e);
        return e;
      });

    return response;
  },
);

export const getMySpecificPool = createAsyncThunk(
  "pools/getMySpecificPool",
  async ({ idOfPool }: { idOfPool: string } & IAppLoaderAction) => {
    let response;
    await axios
      .post(`${apiUrls.pools.getMy}/${idOfPool}`)
      .then((res) => {
        response = res.data;
      })
      .catch((e) => {
        console.error(e);
      });

    return response;
  },
);

export const updateStatusToReviewForSpecificPool = createAsyncThunk(
  "pools/updateStatusToReviewForSpecificPool",
  async ({ idOfPool }: { idOfPool: string } & IAppLoaderAction) => {
    let response;
    await axios
      .post(`${apiUrls.pools.updateMy}/${idOfPool}`)
      .then((res) => {
        console.log(res.data);
        response = res.data;
      })
      .catch((e) => console.error(e));

    return response;
  },
);

const initialState: IPools = {
  fullListOfMyPools: [],
  specificPool: {},
  projectsThatAreNotInThePool: [],
  groupsThatAreNotInThePool: [],
};

export const poolsSlice = createSlice({
  name: "pools",
  initialState,
  reducers: {
    clearAllPools: (state: IPools) => {
      state.fullListOfMyPools = [];
    },
    updatefullListOfMyPools: (state: IPools, { payload }) => {
      if (state.fullListOfMyPools?.findIndex((x) => x.id == payload.id) !== undefined) {
        state.fullListOfMyPools[
          state.fullListOfMyPools?.findIndex((x) => x.id == payload.id) as any
        ] = payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFullListOfMyPools.fulfilled, (state: IPools, { payload }) => {
      state.fullListOfMyPools = payload;
    });
    builder.addCase(getMySpecificPool.fulfilled, (state: IPools, { payload }) => {
      state.specificPool = payload;
    });
    builder.addCase(updateStatusToReviewForSpecificPool.fulfilled, (state: IPools, action) => {
      state.specificPool = action.payload;
      poolsSlice.caseReducers.updatefullListOfMyPools(state, action);
    });
  },
});

export const poolsReducer = poolsSlice.reducer;

export const getMyCurrentFullListOfPools = (state: RootState) => state.pools.fullListOfMyPools;
export const getMyCurrentSpecificPool = (state: RootState) => state.pools.specificPool;

export const { clearAllPools } = poolsSlice.actions;
