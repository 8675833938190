import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { Navigation } from "ui";

const PoolsWrapper = () => {
  const { t } = useTranslation();
  // const tabs = [
  //   { title: t("pages"), urlEndPoint: "list" },
  //   { title: t("pages"), urlEndPoint: "list" },
  // ];
  return (
    <>
      <Navigation />
      <Outlet />
    </>
  );
};

export default PoolsWrapper;
