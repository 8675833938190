import { configureStore } from "@reduxjs/toolkit";
import { authReducer } from "../../features/auth/state/authSlice";
import modalReducer from "./slice/modal/modalSlice";
import { appStateReducer } from "./slice/appStateSlice";
import { loadingHandler } from "./loaderHandleMiddleware";
import { createLogger } from "redux-logger";
import { projectsReducer } from "../../features/projects/state/projectsSlice";
import { zoomMeetingsReducer } from "../../features/zoomMeetings/state/zoomMeetingsSlice";
import { groupsReducer } from "../../features/groups/state/groupsSlice";
import { myUserReducer } from "../../features/my-user/state/myUserSlice";
import { poolsReducer } from "../../features/pools/state/poolsSlice";
import { configsReducer } from "../../features/config-collectiontype/state/configSlice";

const logger = createLogger({});

export const store = configureStore({
  reducer: {
    modals: modalReducer,
    auth: authReducer,
    app: appStateReducer,
    zoomMeetings: zoomMeetingsReducer,
    groups: groupsReducer,
    configs: configsReducer,
    myUser: myUserReducer,
    pools: poolsReducer,
    projects: projectsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(loadingHandler).concat(logger),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
