import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ButtonIcon, ButtonText } from "ui";
import logo from "../../assets/logo.png";
import { getCurrentInfoAboutMyUser, getMyUser } from "../../features/my-user/state/myUserSlice";
import { AppDispatch, RootState } from "../../common/state/store";
import SmZoomMeetingsDataTable, {
  DASHBOARD_MEETINGS_COMPONENT_ID,
} from "./sm-zoom-meetings-data-table";
import { getSmListOfZoomMeetings } from "../../features/zoomMeetings/state/zoomMeetingsSlice";
import { Transition } from "@headlessui/react";
import { useEffect, useState } from "react";
import { CheckCircleIcon, DocumentTextIcon, FolderOpenIcon, BoltIcon } from "@heroicons/react/24/outline";
import ReactPlayer from "react-player";
import { Spinner } from "ui";
import bgImage from "../../assets/bgMyCtoFriend.jpeg";
import {
  getCurrentWelcomeVideoUrl,
  getWelcomeVideoUrl,
} from "../../features/config-collectiontype/state/configSlice";

export const MYUSER_COMPONENT_ID = "myUserComponent";

const Dashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatch: AppDispatch = useDispatch();

  const { user, welcomeVideoUrl } = useSelector((state: RootState) => {
    return {
      user: getCurrentInfoAboutMyUser(state),
      welcomeVideoUrl: getCurrentWelcomeVideoUrl(state),
    };
  });

  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch(
      getMyUser({
        componentId: MYUSER_COMPONENT_ID,
      }),
    );

    dispatch(
      getWelcomeVideoUrl({
        componentId: MYUSER_COMPONENT_ID,
      }),
    );
  }, []);

  return (
    <div
      className="h-screen bg-cover"
      style={{
        backgroundImage: `url('${bgImage}')`,
        boxShadow: "inset 0px 0px 400px 400px rgba(0, 0, 0, .03)",
      }}
    >
      <div className="flex w-full h-full py-10 px-6 items-center justify-center">
        <div className="w-full h-auto max-w-7xl bg-white rounded-xl shadow-lg px-6 py-6">
          <div className="flex justify-center w-full items-end mb-10 mt-4">
            <h1 className="hidden sm:block md:text-xl lg:text-3xl xl:text-4xl font-bold text-black">
              <span className="text-black">Welcome to the</span>
            </h1>
            <img
              className="object-scale-down mx-3  lg:mx-4 xl:mx-6 mb-1 w-40 md:w-48 lg:w-56 xl:w-64"
              src={logo}
              alt="myctofriend-logo"
            />
            <h1 className="hidden sm:block md:text-xl lg:text-3xl xl:text-4xl font-bold text-black">
              coaching app!
            </h1>
          </div>
          <div className="flex-col lg:flex-row flex h-full min-h-[300px]">
            <div className="flex flex-col h-full lg:w-1/2 w-full justify-center mt-3 lg:pr-3 pr-0">
              <div className="flex w-full items-center">
                <h1 className="text-2xl font-bold text-black">Personal Meeting Notes:</h1>
                <div className="flex justify-end align-middle ml-auto">
                  <button
                    className="btn-secondary-fill text-black ml-4 shadow w-40"
                    onClick={() => {
                      window.open(
                        `https://docs.google.com/document/d/${user.googledocouidshared}`,
                        "_blank",
                        "noreferrer",
                      );
                    }}
                  >
                    <div className="flex justify-center items-center text-xl w-full">
                      <DocumentTextIcon className="text-center w-7 mr-2" />
                      <div className="inline-flex items-center text-xl">{t("general.open")}</div>
                    </div>
                  </button>
                </div>
              </div>
              {user?.projectsowner?.length > 0 ? (
                <>
                  <h1 className="text-2xl font-bold text-black mt-6 mb-3">Your project:</h1>
                  <div className="flex flex-col w-full">
                    <div className="flex flex-row w-full mb-4 items-center">
                      <p className="text-xl text-black">{user?.projectsowner[0]?.name}</p>
                      <div className="flex justify-end align-middle ml-auto">
                        <Link
                          to="/profile/myProject"
                          className="btn-secondary-fill text-black ml-4 shadow w-40"
                        >
                          <div className="flex justify-center items-center text-xl w-full">
                            <FolderOpenIcon className="text-center w-7 mr-2" />
                            <div className="inline-flex items-center text-xl">
                              {t("general.open")}
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="flex w-full items-center mt-6 mb-3">
                  <h1 className="text-2xl font-bold text-black">Tell us about your project:</h1>
                  <div className="flex justify-end align-middle ml-auto">
                    <Link
                      to="/profile/myProject"
                      className="btn-secondary-fill text-black ml-4 shadow w-40"
                    >
                      <div className="flex justify-center items-center text-xl w-full">
                        <BoltIcon className="text-center w-7 mr-2" />
                        <div className="inline-flex items-center text-xl">{t("general.letsDoIt")}</div>
                      </div>
                    </Link>
                  </div>
                </div>
              )}
              {user?.groups?.length > 0 && (
                <>
                  <h1 className="text-2xl font-bold text-black mt-6 mb-3">Groups metting notes:</h1>
                  <div className="flex flex-col w-full">
                    {user?.groups?.map((group: any) => (
                      <div className="flex flex-row w-full mb-4 items-center">
                        <p className="text-xl text-black">{group?.name} Notes:</p>
                        <div className="flex justify-end align-middle ml-auto">
                          <button
                            className="btn-secondary-fill text-black ml-4 shadow w-40"
                            onClick={() => {
                              window.open(
                                `https://docs.google.com/document/d/${group?.googledocouidshared}`,
                                "_blank",
                                "noreferrer",
                              );
                            }}
                          >
                            <div className="flex justify-center items-center text-xl w-full">
                              <DocumentTextIcon className="text-center w-7 mr-2" />
                              <div className="inline-flex items-center text-xl">
                                {t("general.open")}
                              </div>
                            </div>
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
            <div className="flex flex-col h-auto lg:w-1/2 w-full justify-center items-center lg:pl-3 pl-0">
              {/* {step.videourl && ( */}
              <div className="relative mt-6 lg:mt-0 w-full aspect-video overflow-hidden rounded-xl">
                {isLoading && <Spinner relative={false} style={{}} />}
                <ReactPlayer
                  className="w-full h-full"
                  width="100%"
                  height="100%"
                  style={{ maxWidth: "72rem" }}
                  controls
                  onReady={() => setIsLoading(false)}
                  url={welcomeVideoUrl}
                />
              </div>
              {/* )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
